body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", arial, sans-serif;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: "Montserrat", arial, sans-serif;

}

.thick-top-nav {
  background-color: rgb(22, 22, 22);
}

.thick-top-nav h4 {
  font-size: 18px;
  color: rgb(247, 247, 247);
  text-align: center;
  line-height: 1.5;
  margin-bottom: 0px;
}

/* .home-banner {
  background-position: center center;
  background-size: auto, cover;
  background-blend-mode: normal;
  background-repeat: no-repeat;
  background-image: linear-gradient(to bottom, rgba(34, 84, 211, 0.25) 0%, rgba(34, 84, 211, 0.25) 100%), url("/public/home-banner.webp");
} */

.logo-img {
  max-height: 100px;
  box-shadow: none;
  margin-top: 0px;
}

.nav-link {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.071em;
  background-color: transparent;
  line-height: 24px;
  color: white;
  padding: 0px 6px 0px 6px;
}

.nav-active {
  font-weight: 800;
}

.banner-home-heading {
  font-size: 42px;
  white-space: pre-line;
  line-height: 1.2;
  color: rgb(255, 255, 255);
  font-family: "Archivo Black", arial, sans-serif !important;
}

.home-banner-basic {
  margin: 160px 0px 160px 0px;
}

.home-banner-basic a,
.contact a {
  font-size: 16px;
  color: rgb(48, 48, 48);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.071em;
  text-decoration: none;
}

.home-banner-basic button,
.contact button {
  background-color: white;
  border: none;
  padding: 15px 35px 15px 35px;
  border-radius: 24px;
}

.contact .whatsapp a {
  font-size: 16px;
  color: rgb(48, 48, 48);
  font-weight: bolder;
  text-transform: none;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  letter-spacing: 0em;
  text-decoration: none;
}

.contact .whatsapp button {
  background-color: white;
  border: none;
  padding: 15px 35px 15px 35px;
  border-radius: 24px;
}

.home-banner .banner-bottom {
  background-color: rgb(34, 84, 211);
  padding-bottom: 56px;
  padding-top: 56px;
  /* margin-top: 100px; */
}

.home-banner .banner-bottom h2 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  line-height: 1.25;
  text-align: center;
}

.web-info-card {
  margin: 60px 0px 60px 0px;
}

.web-info-card img {
  width: 365px;
  height: 274px;
}

.web-info-card h4 {
  font-size: 24px;
  color: rgb(21, 21, 21);
  line-height: 1.25;
  text-transform: uppercase;
}

.web-info-card p {
  font-size: 18px;
  color: rgb(87, 87, 87);
  line-height: 1.5;
}

.web-info-card a {
  font-size: 16px;
  /* color: rgb(48, 48, 48); */
  color: rgb(255, 255, 255);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.071em;
  text-decoration: none;
}

.web-info-card button {
  background-color: rgb(68, 109, 243);
  border: none;
  padding: 15px 35px 15px 35px;
  border-radius: 24px;
}

.home .email {
  background-color: rgb(34, 84, 211);
  padding-bottom: 56px;
  padding-top: 56px;
}

.home .email h2 {
  font-size: 42px;
  margin-bottom: 32px;
  line-height: 1.25;
  color: rgb(255, 255, 255);
  font-family: "Archivo Black", arial, sans-serif;
  font-weight: 400;
}

.home .email p {
  font-size: 24px;
  margin-bottom: 16px;
  line-height: 1.5;
  color: rgb(255, 255, 255);
}

.home .email a {
  font-size: 16px;
  color: rgb(48, 48, 48);
  /* padding-right: 40px;
  padding-left: 40px; */
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.071em;
  text-decoration: none;
}

.home .email button {
  background-color: white;
  border: none;
  padding: 15px 35px 15px 35px;
  border-radius: 24px;
}

/* .home .email .form-floating input {
  background-color: transparent !important;
}

.home .email .form-floating input:focus+label {
  background-color: transparent !important;
}

.home .email.form-control {
  border-color: white !important;
}

.home .email .form-floating label {
  color: white;
} */

/* email-register */

.home .email-register {
  background-color: rgb(34, 84, 211);
  padding-bottom: 56px;
  padding-top: 56px;
}

.home .email-register h2 {
  font-size: 42px;
  margin-bottom: 32px;
  line-height: 1.25;
  color: rgb(255, 255, 255);
  font-family: "Archivo Black", arial, sans-serif;
  font-weight: 400;
}

.home .email-register p {
  font-size: 18px;
  margin-bottom: 16px;
  line-height: 1.5;
  color: rgb(255, 255, 255);
}

.home .email-register a {
  font-size: 16px;
  color: rgb(48, 48, 48);
  /* padding-right: 40px;
  padding-left: 40px; */
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.071em;
  text-decoration: none;
}

.home .email-register button {
  background-color: white;
  border: none;
  padding: 15px 35px 15px 35px;
  border-radius: 24px;
}

/* .home .email-register .form-floating input {
  background-color: transparent !important;
}

.home .email-register .form-floating input:focus+label {
  background-color: transparent !important;
}

.home .email-register.form-control {
  border-color: white !important;
}

.home .email-register .form-floating label {
  color: white;
} */

footer {
  background-color: rgb(34, 84, 211);
  padding-top: 32px;
  padding-bottom: 32px;
}

footer p {
  font-size: 16px;
  color: rgb(255, 255, 255);
}

footer a {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.071em;
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.home .soon,
.academy .soon {
  background-color: rgb(246, 246, 246);
  padding-top: 56px;
  padding-bottom: 56px;
}

.home .soon .post,
.academy .soon .post {
  background-color: white;
  padding-top: 16px;
  padding-bottom: 16px;
}

.home .soon .post span,
.academy .soon .post span {
  font-size: 18px;
  color: rgb(89, 89, 89);
  line-height: 1.5;
  /* background-color: white; */
}

.home .faqs h2,
.course-detail h2 {
  font-size: 42px;
  text-align: center;
  color: rgb(34, 84, 211);
  font-family: "Archivo Black", arial, sans-serif;
}

.home .faqs .table>:not(caption)>*>*,
.academy .course-structure .table>:not(caption)>*>* {
  padding: 0rem 0rem !important;
}

.home .faqs .table tr td h3,
.academy .course-structure .table tr td h3 {
  font-size: 24px;
  cursor: pointer;
  color: rgb(27, 27, 27);
  margin-bottom: 20px;
  margin-top: 20px;
}

.home .faqs .table tr td h3:hover,
.academy .course-structure .table tr td h3:hover {
  color: rgb(34, 84, 211);
}

.home .faqs .table tr td p,
.academy .course-structure .table tr td p {
  font-size: 18px;
  color: rgb(94, 94, 94);
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 20px;
}

.home .faqs .table .table-row,
.academy .course-structure .table .table-row {
  border-bottom: 1px solid rgb(226, 226, 226);
}

.home .instagram,
.course-listing,
.course-detail {
  /* background-color: rgb(246, 246, 246); */
  /* padding-bottom: 56px;
  padding-top: 56px; */
}

.home .instagram h2 {
  font-size: 42px;
  text-align: center;
  color: rgb(34, 84, 211);
  font-family: "Archivo Black", arial, sans-serif;
}

.home .instagram .InstaPost {
  height: 272px;
  background-position: center center;
  background-size: cover;
  width: 100%;
  cursor: pointer;
  transition: opacity 0.3s ease, background-color 0.3s ease;
  /* transition: all 0.5s ease 0.3s; */
}

/* .home .instagram .InstaPost svg {
  cursor: pointer;
  left: 242px;
  top: 222px;
  color: rgb(226, 226, 226);
  position: relative;
  visibility: hidden;
  transition: visibility 0.5s ease 0.3s;
} */

.home .instagram .InstaPost svg {
  cursor: pointer;
  position: relative;
  left: 95%;
  top: 96%;
  transform: translate(-95%, -96%);
  color: rgb(226, 226, 226);
  opacity: 0;
  transition: opacity 0.5s ease 0.3s;
}

.home .instagram .InstaPost:hover {
  opacity: 0.8;
  background-color: rgba(246, 246, 246, 0.5);
}

.home .instagram .InstaPost:hover svg {
  opacity: 1;
}

.home .soon .post,
.academy .soon .post,
.home .faqs,
.home .email-register .e-r-form,
.home .email .e-form,
.academy .course-structure {
  margin: 0px 235px !important;
}

@media only screen and (max-width: 800px) {

  .home .soon .post,
  .academy .soon .post,
  .home .faqs,
  .home .email-register .e-r-form,
  .home .email .e-form,
  .academy .course-structure {
    margin: 0px 0px !important;
  }

  .contact-form {
    margin: 0px !important;
  }

  .web-info-card img {
    width: 265px !important;
    height: 150px !important;
  }

  .academy .about .a-card img {
    width: 265px !important;
    height: 150px !important;
  }

  .home .email button,
  .home .email-register button,
  .home-banner-basic button,
  .web-info-card button {
    width: 100% !important;
  }

  .academy .register button {
    width: 100% !important;
    margin-top: 15px;
  }

  .common-modal {
    width: 100% !important;
    right: 0px !important;
    /* margin-right: 10px !important; */
    /* margin-left: 10px !important; */
  }

  .create-account form,
  .login form {
    width: 100% !important;
  }
}

/* Academy css */

/* .course-listing .banner {
  background-repeat: no-repeat;
  background-blend-mode: normal;
  background-size: auto, cover;
  background-position: 49.5652% 42.1739%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.37) 0%, rgba(0, 0, 0, 0.37) 100%), url("/public/academy-banner.webp");
} */

.academy .banner .title,
.course-listing .banner .title {
  padding-bottom: 56px;
  padding-top: 56px;
}

.academy .banner .title h2,
.course-listing .title h2 {
  font-size: 56px;
  line-height: 1.125;
  color: rgb(255, 255, 255);
  font-family: "Archivo Black", arial, sans-serif;
  font-weight: 400;
}

.academy .banner a,
.course-listing .banner a {
  font-size: 16px;
  /* color: rgb(48, 48, 48); */
  color: rgb(255, 255, 255);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.071em;
  text-decoration: none;
}

.academy .banner button,
.course-listing .banner a {
  background-color: rgb(34, 84, 211);
  border: none;
  padding: 15px 35px 15px 35px;
  border-radius: 24px;
}

.academy .about {
  background-color: rgb(246, 246, 246);
  padding-bottom: 56px;
  padding-top: 56px;
}

.academy .about h2 {
  font-size: 42px;
  text-align: center;
  color: rgb(34, 84, 211);
  font-family: "Archivo Black", arial, sans-serif;
}

.academy .about .a-card img {
  width: 365px;
  height: 183px;
}

.academy .about .a-card h4 {
  font-size: 24px;
  color: rgb(21, 21, 21);
  line-height: 1.25;
  text-transform: uppercase;
}

.academy .about .a-card p {
  font-size: 18px;
  color: rgb(87, 87, 87);
  line-height: 1.5;
}

.academy .about .a-card a {
  font-size: 16px;
  color: rgb(255, 255, 255);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.071em;
  text-decoration: none;
}

.academy .about .a-card button {
  background-color: rgb(68, 109, 243);
  border: none;
  padding: 15px 35px 15px 35px;
  border-radius: 24px;
}

.academy .course-structure h2 {
  font-size: 42px;
  text-align: center;
  color: rgb(34, 84, 211);
  font-family: "Archivo Black", arial, sans-serif;
}

.academy .register h3 {
  font-size: 36px;
  color: rgb(34, 84, 211);
}

.academy .register .custom-input {
  border-color: black !important;
}

.academy .register a,
.create-account form a,
.login form span,
.modal-common-form span {
  font-size: 16px;
  color: rgb(255, 255, 255);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.071em;
  text-decoration: none;
}

.academy .register button,
.create-account form button,
.login form button,
.modal-common-form button {
  background-color: rgb(34, 84, 211);
  border: none;
  padding: 15px 35px 15px 35px;
  border-radius: 24px;
}

.academy .course-button {
  background-color: white;
  border: none;
  padding: 15px 35px 15px 35px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.071em;
  text-decoration: none;
}



.course-card button {
  background-color: rgb(34, 84, 211);
  border: none;
  width: 100%;
  height: 45px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.course-card span {
  font-size: 11px;
  color: rgb(255, 255, 255);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.071em;
  text-decoration: none;
}

.academy .register p {
  color: rgb(94, 94, 94);
  line-height: 1.5;
  font-weight: 400;
  text-align: center;
}

/* contact */

.contact {
  padding-bottom: 56px;
  padding-top: 56px;
  background-color: rgb(34, 84, 211);
}

.contact h1 {
  font-size: 24px;
  margin-bottom: 24px;
  line-height: 1.25;
  color: rgb(255, 255, 255);
  font-weight: 400;
}

.contact p {
  color: rgb(255, 255, 255);
}

.contact img {
  width: 100%;
  height: 50.11;
}

/* .contact .contact-form form .form-floating>.form-control:not(:placeholder-shown)~label::after {
  background-color: transparent !important;
}
.contact .contact-form form .form-floating input {
   background-color: transparent !important;
   color: white !important;
}
.contact .contact-form form .form-floating>label {
  color: white !important;
}
.contact .contact-form form .form-floating input:focus {
  background-color: transparent !important;
  caret-color: white !important;
  color: white !important;
}
.contact .contact-form form .form-floating input:focus+label::after {
  background-color: transparent !important;
  color: white !important;
} */



.contact .contact-form form .form-floating>.form-control:not(:placeholder-shown)~label::after,
.home .email-register .form-floating>.form-control:not(:placeholder-shown)~label::after,
.home .email .form-floating>.form-control:not(:placeholder-shown)~label::after,
.contact .contact-form form .form-floating input,
.home .email-register .form-floating input,
.home .email .form-floating input,
.contact .contact-form form .form-floating>label,
.home .email-register .form-floating>label,
.home .email .form-floating>label,
.contact .contact-form form .form-floating input:focus,
.home .email-register .form-floating input:focus,
.home .email .form-floating input:focus,
.contact .contact-form form .form-floating input:focus+label::after,
.home .email .form-floating input:focus+label::after,
.home .email-register .form-floating input:focus+label::after,
.contact .contact-form form .form-group textarea,
.home .email .form-group textarea,
.contact .contact-form form .form-group textarea:focus,
.home .email .form-group textarea:focus {
  background-color: transparent !important;
  color: white !important;
}

.contact .contact-form form .form-floating input:focus,
.home .email-register .form-floating input:focus,
.home .email .form-floating input:focus,
.contact .contact-form form .form-group textarea:focus,
.home .email .form-group textarea:focus {
  caret-color: white !important;
}

.contact .contact-form form .form-floating input:focus+label::before,
.home .email .form-floating input:focus+label::before,
.home .email-register .form-floating input:focus+label::before {
  color: black;
}

.contact .contact-form form .form-group textarea::placeholder,
.home .email .form-group textarea::placeholder {
  color: white;
}

.contact-form span {
  cursor: pointer;
  font-size: 18px;
  margin-left: 16px;
  text-decoration: underline;
  line-height: 1.5;
  font-weight: 400;
  color: rgb(255, 255, 255);
}

.instagram-link a {
  font-size: 18px;
  vertical-align: middle;
  color: rgb(34, 84, 211);
  text-align: center;
}

.contact-form h4 {
  font-size: 24px;
  line-height: 1.25;
  color: rgb(255, 255, 255);
  margin-bottom: 24px;
}

/* .contact-form {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.5s, transform 0.5s;
}

.contact-form.show {
    opacity: 0;
    transform: translateX(100%);
} */

#slide {
  position: relative;
  left: -600px;
  /* width: 100%;
  height: 370px; */
  -webkit-animation: slide 0.5s forwards;
  animation: slide 0.5s forwards;
}

@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}

.navbar-transparent {
  background-color: transparent !important;
}

.navbar-colored {
  background-color: rgb(34, 84, 211) !important;
}

.sticky-top-custom {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
}

.create-account h2,
.login h2 {
  font-size: 42px;
  text-align: center;
  color: rgb(34, 84, 211);
  font-family: "Archivo Black", arial, sans-serif;
}

.create-account form input,
.login form input {
  padding-bottom: 14px;
  padding-top: 14px;
}

.create-account form,
.login form {
  width: 62%;
}

.create-account form p,
.login form p,
.modal-common-form p {
  font-size: 18px;
  line-height: 1.5;
  color: rgb(94, 94, 94);
}

.modal-common-form p,
.modal-common-form p a {
  font-size: 14px !important;
}

.create-account form p a,
.login form p a,
.modal-common-form p a {
  font-size: 18px;
  color: rgb(34, 84, 211);
  text-transform: none;
  font-weight: 400;
  text-decoration: none;
}

.create-account form .form-group label {
  font-size: 18px;
  color: rgb(94, 94, 94);
}

.dropdown-toggle::after {
  border: 0px !important;
}

.dropdown-menu[data-bs-popper] {
  left: -195px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 6px 3px;
  width: 240px;
  z-index: 1003;
  overflow-y: auto;
  white-space: nowrap;
  background-color: rgb(34, 84, 211);
  border: none;
}

.dropdown-item {
  color: rgb(255, 255, 255);
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.071em;
  /* display: inline; */
}

.dropdown-item:hover {
  color: rgb(255, 255, 255);
  background-color: transparent;
}

.message-bottom-button {
  border-radius: 50%;
  position: fixed;
  z-index: 100;
  bottom: 40px;
  right: 18px;
  color: white;
  font-size: 17px;
  text-decoration: none;
  background-color: rgb(34, 84, 211);
  width: 65px;
  height: 65px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 18px;
  border: none;
}

.message-bottom-close-button {
  border-radius: 50%;
  position: fixed;
  z-index: 100;
  bottom: 40px;
  right: 18px;
  color: white;
  font-size: 17px;
  text-decoration: none;
  background-color: rgb(85, 85, 85);
  width: 65px;
  height: 65px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 18px;
  border: none;
}

/* .modal-content{
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 40px;
  padding-top: 16px;
} */
.modal-common-header {
  background-color: rgba(34, 84, 211);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.modal-common-header h2 {
  font-size: 24px;
  line-height: 1.25;
  color: rgb(255, 255, 255);
}

.modal-common-form,
.modal-common-header {
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 40px;
  padding-top: 16px;
}

.common-modal {
  position: fixed;
  z-index: 101;
  background-color: white;
  border-radius: 7px;
  width: 382px;
  right: 0;
  top: 5%;
  transform: translateY(-5%);
  max-height: 80vh;
  overflow-y: auto;
  border: 1px solid white;
  right: 18px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.message-icon {
  margin-right: 24px;
  margin-left: 24px;
}

.message-icon svg {
  height: 16px;
  margin-bottom: 11px;
  margin-left: 10px;
  margin-top: -3px;
  color: rgb(193 205 237);
}

.message-box {
  background-color: rgb(193 205 237);
  border-radius: 5px;
  margin-right: 24px;
  margin-left: 24px;
  margin-top: -24px;
}

.message-box p {
  padding: 12px 15px;
  color: rgb(48, 48, 48);
  font-size: 16px;
  line-height: 1.5;
  margin: 0px;
}

.mobile-nav-sidebar {
  background-color: white;
  height: 100vh;
  position: fixed;
  z-index: 105;
}

.mobile-sidebar-out {
  -webkit-animation: mobile-sidebar-out 0.5s forwards;
  animation: mobile-sidebar-out 0.5s forwards;
}

.mobile-sidebar .modal.fade .modal-dialog {
  transform: none !important;
}

.mobile-sidebar {
  position: fixed;
  left: -600px;
  -webkit-animation: mobile-sidebar-in 0.5s forwards;
  animation: mobile-sidebar-in 0.5s forwards;
}

@-webkit-keyframes mobile-sidebar-in {
  0% {
    left: -600px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes mobile-sidebar-in {
  0% {
    left: -600px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@-webkit-keyframes mobile-sidebar-out {
  0% {
    left: 0;
    opacity: 1;
  }

  100% {
    left: -600px;
    opacity: 0;
    bottom: 0px;
  }
}

@keyframes mobile-sidebar-out {
  0% {
    left: 0;
    opacity: 1;
  }

  100% {
    left: -600px;
    opacity: 0;
    bottom: 0px;
  }
}

/* @-webkit-keyframes mobile-sidebar {
  100% {
    left: 0;
  }
}

@keyframes mobile-sidebar {
  100% {
    left: 0;
  }
} */


/* .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 105;
} */
/* .mobile-sidebar {
  z-index: 105;
} */
.mobile-sidebar .modal-content {
  background-color: rgb(22, 22, 22) !important;
  border-radius: 0px;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.mobile-sidebar .modal-dialog {
  margin: 0px;
}

.mobile-sidebar .header {
  color: white;
}

.sidebar-nav-link {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  border-bottom: 1px solid rgba(76, 76, 76, 0.5);
}

.mobile-sidebar .active {
  font-weight: 700;
}

.mobile-sidebar .modal-body span {
  font-size: 22px;
  color: rgb(247, 247, 247);
  text-transform: none;
  letter-spacing: normal;
  /* font-family: 'Montserrat', arial, sans-serif; */
}

/* .modal-common-form textarea .form-control::placeholder {
  color: black !important;
} */

.transparent-search-bar {
  background-color: rgba(255, 255, 255, 0.15);
  border: none;
  border-radius: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.5); */
  color: white;
}

.transparent-search-bar::placeholder {
  color: rgb(191, 191, 191);
  font-size: 16px;
}

.transparent-search-bar:focus {
  background-color: rgba(255, 255, 255, 0.15);
  outline: none;
  box-shadow: none;
  /* border-color: rgba(255, 255, 255, 0.5); */
  color: white;
  border: 1px solid white;
}

.course-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);

}

.course-card .upper-section {
  /* background-color: white;   */
  padding: 25px 25px 0px 25px;
}

.course-card .upper-section h3 {
  font-family: "Archivo Black", arial, sans-serif !important;
  font-size: 19px;
  color: black;
}

.course-card .upper-section p {
  margin-bottom: 0px;
}

.course-detail .description {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
}

.course-card img {
  /* max-width: 100%;
  width: 310px;
  height: 200px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px; */
  width: 100%;
  height: 200px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

/* .course-chapters h5 {
  font-weight: bold;
  cursor: pointer;
} */

/* .course-chapter-lesson i, .course-lesson-side-icon i {
  background-color: rgba(34, 84, 211);
  color: white;
  padding: 4px;
  border-radius: 50%;
} */

.course-lesson-side-icon {
  color: rgba(34, 84, 211);
  font-weight: bold;
  background-color: rgba(34, 84, 211, 0.2);
  padding: 6px;
  border-radius: 6px;
}

.course-detail .header {
  background-color: #2d2f31;
  padding-bottom: 3.2rem;
  padding-top: 3.2rem;
}

.course-detail .header h2,
.academy .header h2 {
  text-align: left;
  font-size: 38px;
  margin-bottom: 0.8rem;
  color: white;
}

.course-detail .header p {
  color: white;
  font-size: 21px;
}

/* .course-description p {
  margin-bottom: 0px !important;
} */
.course-description .desc {
  max-height: 100px;
  font-size: 15px;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.course-description .expanded {
  max-height: none;
}

.course-lesson-description .desc {
  max-height: 40vh;
  /* min-height: 100%; */
  font-size: 15px;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.course-lesson-description .expanded {
  max-height: none;
}


.course-chapters i {
  /* border-radius: 50%; */
  color: white;
  background-color: rgba(34, 84, 211);
  padding: 2px;
  border: 2px solid rgba(34, 84, 211);
}

.course-chapters .empty-circle {
  /* border-radius: 50%; */
  color: transparent;
  background-color: transparent;
  padding: 2px;
  border: 2px solid rgba(34, 84, 211);
}

.course-chapter-lesson .status-circle {
  color: white;
  background-color: rgba(34, 84, 211);
  padding: 1px;
  border: 1px solid rgba(34, 84, 211);
}

.course-chapter-lesson .empty-status-circle {
  color: transparent;
  background-color: transparent;
  padding: 1px;
  border: 1px solid rgba(34, 84, 211);
}

.course-lesson-detail h4 {
  color: rgba(34, 84, 211);
  font-size: 24px;
  text-align: center;
  font-weight: 700;
}

.course-lesson-image img {
  /* min-height: 250px; */
  max-height: 250px;
  width: 100%;
}

.latest-courses {
  position: sticky;
  top: 100px;
}